import React, { memo } from 'react'
import { graphql } from 'gatsby'

// @ts-ignore
import { DonorCampaignLandingQuery } from '__generated__/gatsby-types'

import AutomatedLanding, {
  AutomatedLandingContextProps,
} from './layouts/AutomatedLanding'

interface Props {
  data: DonorCampaignLandingQuery
  pageContext: AutomatedLandingContextProps
}

const DonorCampaignLanding = ({ data, pageContext }: Props) => {
  const d = data.datoCmsAutomatedLanding

  return (
    <AutomatedLanding
      keyId={d.originalId}
      heading={d.pageTitleNode.childMarkdownRemark.html}
      heroText={d.text}
      metaTags={d.seoMetaTags}
      {...pageContext}
      cards={data.allDatoCmsDonorCampaign.edges}
      summaryItemName={d.summaryItemName}
    />
  )
}

export default memo(DonorCampaignLanding)

export const pageQuery = graphql`
  query DonorCampaignLandingQuery(
    $originalId: String!
    $skip: Int!
    $perPage: Int!
  ) {
    datoCmsAutomatedLanding(originalId: { eq: $originalId }) {
      originalId
      pageTitleNode {
        childMarkdownRemark {
          html
        }
      }
      text
      summaryItemName
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsDonorCampaign(
      limit: $perPage
      skip: $skip
      sort: { fields: position }
    ) {
      edges {
        node {
          ...donorCampaignCard
        }
      }
    }
  }
`
